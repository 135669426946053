<template>
  <div class="base-form">
    <page-form
      ref="baseFormRef"
      :baseFormConfig="baseFormConfig"
      @handleNewClick="handleSubmitClick"
      :pageTitle="pageTitle"
      :pageInfo="pageInfo"
      @resetBtnClick="handleReset"
      :isResetFields="false"
    >
      <template #customForm>
        <el-col :span="12">
          <el-form-item
            required
            :label="$t('user.resource-menu')"
            class="form-item"
          >
            <resource-tree
              class="resource-menu"
              ref="resourceTreeRef"
              :style="{ height: '300px', width: '100%' }"
              :resources="allTreeResource"
              @handle-check-change="handleCheckChange"
              @handle-node-click="handleNodeClick"
              @handle-check="handleCheckEvent"
            ></resource-tree>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item class="form-item">
            <template #label>
              <span>{{ $t('user.resource-btn') }}</span>
              <span
                v-if="currentMenuTitle"
                :style="{
                  color: $store.getters.cssVar.menuBg,
                  'font-weight': 'bold',
                  'margin-left': '10px'
                }"
                >{{ currentMenuTitle }}</span
              >
            </template>
            <btn-table
              ref="resourceBtn"
              :listData="btnListData"
              @handle-selected-btns="handleSelectedBtns"
            ></btn-table>
          </el-form-item>
        </el-col>
      </template>
    </page-form>
  </div>
</template>

<script setup>
import pageForm from '@/components/PageForm/page-form.vue'
import { baseFormConfig } from './config/resource.edit.js'
import { useStore } from 'vuex'
import { ref, onBeforeMount, getCurrentInstance } from 'vue'
import i18n from '@/i18n'
import _ from 'lodash' // 导入loadsh插件
import { useRoute, useRouter } from 'vue-router'
import resourceTree from '@/components/resourceMenu/resource-tree.vue'
import btnTable from '@/components/resourceMenu/btn-table.vue'
import { getItem, setItem } from '@/utils/storage'
import { RESOURCEMENUBTNLIST } from '@/utils/constant/index'
import { ElMessage } from 'element-plus'

const pageTitle = ref('')
const { t } = i18n.global
const { appContext } = getCurrentInstance()
const handleMessageCommit =
  appContext.config.globalProperties.$handleMessageCommit

pageTitle.value = t('general.router-resource-group-edit')
const store = useStore()
const router = useRouter()
const route = useRoute()
const id = route.params.id

const resourceBtn = ref('')
const allTreeResource = ref([])
const selectedResources = ref([])
const btnListData = ref([])
const btnList = ref([])

const pageInfo = ref({})
const resourceTreeRef = ref(null)
const resourceTreeParentNodeID = ref(0)
const currentMenuTitle = ref('')
const selectedBtns = ref([])
const initalSelectedBtns = ref([])
const currentMenuID = ref(0)

const resuorceMenuBtnList = store.getters.resuorceMenuBtnList || []

const initData = async () => {
  // 根据当前id获取页面初始化数据
  const data = await store.dispatch('user/getResourceGroupById', id)
  pageInfo.value = data
  const permissionMenuList = await store.dispatch(
    'user/getPermissionMenuList',
    id
  )
  selectedResources.value = []
  if (permissionMenuList && permissionMenuList.length) {
    for (const index in permissionMenuList) {
      selectedResources.value.push(permissionMenuList[index].id)
    }
  }
  resourceTreeRef.value.handleInitialEditData(selectedResources.value)
}

// 获取资源树菜单
const initMenuList = async () => {
  const resuorceMenu = _.cloneDeep(resuorceMenuBtnList)
  allTreeResource.value = [
    {
      id: resourceTreeParentNodeID.value,
      name: 'Resources',
      children: resuorceMenu,
      level: 0
    }
  ]
  const result = handleEntireTree(allTreeResource.value)
  allTreeResource.value = result
}

// 初始化有权限的按钮数组
const initPermissionBtnList = async () => {
  const data = await store.dispatch('user/getPermissionBtnList', id)
  if (data && data.length > 0) {
    selectedBtns.value = data.map((item) => item.id)
    initalSelectedBtns.value = _.cloneDeep(selectedBtns.value)
  }
}

// 将资源树数据格式化
const handleEntireTree = (resources) => {
  return resources.map((item) => {
    if (item.children && item.children.length > 0 && item.level < 2) {
      item.children = handleEntireTree(item.children)
    } else {
      item.children = []
    }
    return {
      id: item.id,
      label: t(item.name),
      children: item.children,
      level: item.level
    }
  })
}

// 收集依赖数据
const handleCheckEvent = (item, treeData) => {
  const isChecked = treeData.checkedNodes.includes(item)
  getCtxBtnResource(item, isChecked)
}
// 资源数菜单选中事件处理
const handleCheckChange = (selectedResource) => {
  selectedResources.value = selectedResource
}

// 获取相关的按钮权限资源
const getCtxBtnResource = (resource, status) => {
  const { level, id, children } = resource
  // 判断level的层级
  if (level === 1 || level === 0) {
    if (children && children.length > 0) {
      children.forEach((item) => getCtxBtnResource(item, status))
    }
    // 查找id下面的另一层级
  } else if (level === 2) {
    getBtnResourceByMenuId(id, status)
  }
}

// 根据MenuId遍历添加btnResourceId
const getBtnResourceByMenuId = (menuId, status) => {
  const btnItems = getBtnList(menuId) || []
  const btnIds = btnItems.map((item) => item.id)
  if (status) {
    // 去重
    selectedBtns.value = [...new Set(selectedBtns.value.concat(btnIds))]
  } else {
    selectedBtns.value = selectedBtns.value.filter(
      (item) => !btnIds.includes(item)
    )
  }
}

// 单独对按钮列表的操作 按钮级别
const handleSelectedBtns = (value, status) => {
  // 判断是单个勾选还是全选操作
  if (typeof value === 'string') {
    selectedBtns.value = status
      ? selectedBtns.value.push(value)
      : selectedBtns.value.filter((item) => item !== value)
  } else {
    selectedBtns.value = status
      ? selectedBtns.value.concat(value)
      : selectedBtns.value.filter((item) => !value.includes(item))
  }
  // 去重
  selectedBtns.value = [...new Set(Array.from(selectedBtns.value))]
}

// 资源树菜单节点的点击
const handleNodeClick = (data) => {
  currentMenuID.value = data.id
  // 判断是否父级id
  const isParentNodes = resourceTreeParentNodeID.value === currentMenuID.value

  // 当点击第一层父级时，则不执行函数
  if (!isParentNodes) clickMenu(data)
}

// 判断按钮id是否在已勾选按钮数组中
const isCheckedBtn = (id) => {
  return selectedBtns.value.includes(id)
}

// 给resuorceMenuBtnList初始化为按钮带勾选参数的数组
const initBtnCheckStatus = () => {
  const resuorceMenu = _.cloneDeep(resuorceMenuBtnList)
  setItem(RESOURCEMENUBTNLIST, handleEntireBtn(resuorceMenu))
}

// 给资源按钮树添加button isChecked属性
const handleEntireBtn = (resources) => {
  return resources.map((item) => {
    if (item.children && item.children.length > 0) {
      // 递归处理
      item.children = handleEntireBtn(item.children)
    }
    if (item.btnList && item.btnList.length > 0) {
      item.btnList = handleEntireBtn(item.btnList)
    }

    if (item.level > 2) {
      return {
        ...item,
        isChecked: isCheckedBtn(item.id)
      }
    }
    return { ...item }
  })
}

// 根据菜单Id初始化当前菜单按钮列表
const initCurrentMenuBtnList = async (id) => {
  // 获取当前点击菜单的button 数组
  const btnArr = getBtnList(id)
  const btnListFormat = []
  for (const i in btnArr) {
    btnListFormat.push({
      id: btnArr[i].id,
      isChecked: btnArr[i].isChecked,
      disable: !isCheckedMenu(id), // 当点击的菜单没有被勾选时,那按钮列表应为禁用状态
      btn: btnArr[i]
    })
  }
  // 展示按钮区
  btnListData.value = btnListFormat
}

// 根据当前id值获取按钮列表
const getBtnList = (id) => {
  const resuorceMenu = getItem(RESOURCEMENUBTNLIST) || []
  resuorceMenu.forEach((item) => {
    if (item.children && item.children.length) {
      item.children.forEach((child) => {
        if (child.id === id) {
          if (child.btnList && child.btnList.length > 0) {
            btnList.value = child.btnList.map((item) => {
              item.isChecked = selectedBtns.value.includes(item.id)
              return item
            })
          } else {
            btnList.value = []
          }
        }
      })
    } else {
      btnList.value = []
    }
  })
  return btnList.value
}

const clickMenu = (data) => {
  initCurrentMenuBtnList(data.id)
  currentMenuTitle.value = t('user.current-resource-menu') + ': ' + data.label
}

// 判断点击的菜单是否被勾选
const isCheckedMenu = (id) => {
  return selectedResources.value.includes(id)
}

const handleReset = async () => {
  initData()
  selectedBtns.value = initalSelectedBtns.value
  initCurrentMenuBtnList(currentMenuID.value)
  // 将resourceTreeRef搜索的值清空
  resourceTreeRef.value.filterText = ''
}

const handleSubmitClick = async (data) => {
  const form = _.cloneDeep(data)
  // 对于半选状态的父级也要传给后端
  const halfCheckedNodes = resourceTreeRef.value
    .getHalfCheckedNodes()
    .map((item) => item.id)
  form.resources = selectedResources.value.concat(
    halfCheckedNodes,
    selectedBtns.value
  )
  form.resources = form.resources.map((item) => {
    return {
      id: item
    }
  })
  if (!form.resources.length) {
    return ElMessage.error(t('popup.check-resource-group'))
  }
  form.id = id
  handleMessageCommit('user/handleEditResourceAction', form).then(() => {
    router.push('/user/resource/group')
  })
}
initMenuList()
initData()
onBeforeMount(async () => {
  // 需要保证加载initPermissionBtnList后再加载initBtnCheckStatus
  await initPermissionBtnList()
  initBtnCheckStatus()
})
</script>

<style lang="scss" scoped>
.base-form {
  .form-item {
    padding: 5px 20px;
    .resource-menu {
      border: 1px solid #dcdfe6;
      overflow-y: scroll;
    }
  }
}
</style>
